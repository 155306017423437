<template>
  <div class="header">
    <div class="header-wrap">
      <div class="header-wrap-container">
        <img class="logo" :src="require('/static/images/guolao2.png')" alt="" @click="headback">
        <!-- <span>知识产权智能管家</span> -->
        <span>开创SaaS+知产服务新模式</span> 
        <div class="header-wrap-box">
          <div style="color:#2e2e34;">服务热线：400-630-2718</div>
          <router-link id="firstpage" tag="div" to="/">首页</router-link>
          
          <!-- <div class="buycart-count"  @click="tobuy">{{num}}</div> -->
          <div class="agency-apply" v-if="agentType == '3'">
            <router-link to="/cityAgency">城市代理人</router-link>
          </div>
          <div class="agency-apply" v-else>
            <router-link to="/agency">代理人中心</router-link>
          </div>
          <div id="image_box" @click="tobuy">
            <img class="buy" :src="require('/static/images/mybuy.png')" alt="">
            <!-- <span style="margin-left:5px">购物车</span> -->
          </div>
          <div v-show="myshow" id="header-wrap-box-login">
            <router-link to="/login">登录/</router-link>
            <router-link to="/register">注册</router-link>
          </div>
          <div id="quit-div" v-show="!myshow">
            <router-link style="float:left;margin-left:15px" tag="div" to="/personal">个人中心/</router-link>
            <div style="float:left;" @click="quit">退出</div>
          </div>
        </div>

      </div>


    </div>
  </div>
</template>

<script>
export default {
  name: "top",
  components: {},
  data() {
    return {
      myshow: true,
      agentType:this.$cookie.getCookie('angentType'),
    }

  },
  mounted() {
    if (this.$cookie.getCookie('u_id')) {
      this.myshow = false;
      // this.getData1();
    }
    if (this.$cookie.getCookie('token')) {
      this.$http.post(this.GLOBAL.new_url + '/user/service/getTokenInUse', {
        userToken: this.$cookie.getCookie('token')
      }, {
        headers: {
          AuthToken: this.$cookie.getCookie('token'),
          'X-Request-Origin': 'xcx'
        }
      }).then(res => {
        if (res.data.code == "116002002") {
          this.$cookie.delCookie('username');
          this.$cookie.delCookie('u_id');
          this.$cookie.delCookie('type');
          this.$cookie.delCookie('user_type');
          this.$cookie.delCookie('consultant');
          this.$cookie.delCookie('token');
          this.$cookie.delCookie('from');
          this.$cookie.delCookie('angentType');
          this.$router.push({path: "/login"});
          this.myshow = true;
          this.tip('error', res.data.msg)
        }
        localStorage.setItem('userName',res.data.data.userName)
      })
    }

  },
  methods: {
    tip(type, msg) {
      return this.$message({
        type: type,
        message: msg,
        showClose: true,
        duration: 2500
      })
    },
    quit() {
      this.$cookie.delCookie('username');
      this.$cookie.delCookie('u_id');
      this.$cookie.delCookie('type');
      this.$cookie.delCookie('user_type');
      this.$cookie.delCookie('consultant');
      this.$cookie.delCookie('token');
      this.$cookie.delCookie('old_id');
      this.$cookie.delCookie('angentType');
      window.localStorage.removeItem('UserAgent')
      this.myshow = true;
      // console.log(this.$route.path)
      if(this.$route.path !== '/'){
        this.$router.push('/');
      }else{
        this.$router.go(0);
      }
    },
    headback() {
      this.$router.push('/')
    },
    tobuy() {
      this.$router.push('/personal/buyCart')
    },
    // getData1() {
    //   this.$http.post(this.GLOBAL.new_url+'/user/service/getPersonInfo',{
    //     userCode:this.$cookie.getCookie('u_id')
    //   },{
    //                 headers:{
    //                     AuthToken:this.$cookie.getCookie('token'),
    //                     'X-Request-Origin':'xcx'
    //                 }
    //                             }).then(res=>{
    //   // console.log(res)
    //   if(res.data.code== "200"){
    //     this.agentType = res.data.data.angentType;
    //   }
    //   })
    // },
  }
}
</script>

<style scoped>
.header-wrap {
  width: 100%;
  height: 54px;
  background-color: #2e2e34;
}

.header-wrap-container {
  width: 1200px;
  height: 54px;
  margin: 0 auto;
  line-height: 54px;
  position: relative;
  z-index: 10;
}

.logo {
  width: 79px;
  height: 26px;
  float: left;
  vertical-align: middle;
  margin: 14px 24px 0 0;
  cursor: pointer;
}

.header-wrap-box {
  width: 590px;
  height: 54px;
  float: left;
  margin-left: 335px;
}

.header-wrap-container > span {
  float: left;
  color: #FFFFFF;
  font-size: 14px;
}

.header-wrap-box > a {
  text-decoration: none;
}

.agency-apply > a {
  text-decoration: none;
  color: #FFFFFF;
}

#header-wrap-box-login, #quit-div {
  width: 120px;
  height: 34px;
  border-radius: 3px;
  background-color: #EB5E00;
  margin-right: 0;
  margin-top: 10px;
  line-height: 34px;
  text-align: center;
  color: #FFFFFF;
}

#header-wrap-box-login > a {
  text-decoration: none;
  color: #FFFFFF;
  font-size: 14px;
}

.header-wrap-box > div {
  float: left;
  color: #FFFFFF;
  font-size: 14px;
  cursor: pointer;
  margin-right: 20px;
}

#image_box {
  line-height: 54px;
  /* margin-right: 3px; */
}

.buycart-count {
  width: 50px;
  text-align: center;
}

.buy {
  width: 20px;
  height: 20px;
  vertical-align: middle;
}
</style>
